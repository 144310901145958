.cursor {
  --size-icon: 195px;
  position: fixed;
  top: calc(var(--size-icon) * -1 / 2);
  left: calc(var(--size-icon) * -1 / 2);
  transform: translate(-50%, -50%);
  pointer-events: none;
  cursor: none;
  /* z-index: 3; */
}

.videoIcon{
  height: var(--size-icon);
  width: var(--size-icon);
  cursor: none;
}

.videoLabel{
  position: absolute;
  text-align: center;
  top: 47%;
  left: 55%;
  transform: translate(-50%,-50%);
  font: var(--font-p2-desktop-default);
  color: var(--beige);
  font-weight: 900;
  z-index: 2;
  cursor: none;
}
