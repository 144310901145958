.container{
  display: grid;
  grid-template-columns: var(--grid-template-desktop);
}

.heroContainer{
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  grid-column: full-start / full-end !important;
  background: var(--beige);
  padding: 0 !important;
  max-height: 900px;
  height: calc(100dvh - var(--header-height));
  min-height: 700px;
}

.heroImg{
  position: relative;
  width: 100%;
  flex: 1 0 auto;
  background: var(--grey);
  clip-path: inset(0 0 0 0);
}

.heroText{
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--white);
  text-align: center;
  z-index: 2;
  font: var(--font-h1-desktop-default);
  text-transform: uppercase;
  pointer-events: none;
}

.backdrop{
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--black);
  opacity: 0.2;
  z-index: 1;
  pointer-events: none;
}

.hasYoutube{
  cursor: none;
}

.isDesktop{
  display: grid;
}

.isMobile{
  display: none;
}

@media (max-width: 1200px){
  .videoButton{
    top: var(--spacing-40);
    right: var(--spacing-40);
  }
}

@media (max-width: 1024px){
  .heroContainer{
    min-height: auto;
  }
  .videoButton{
    --size-icon: 85px;
  
    top: var(--spacing-24);
    right: var(--spacing-24);
  }
  
  .videoLabel{
    font: var(--font-p3-mobile-default);
    font-weight: 900;
  }

  .heroText{
    font: var(--font-h1-mobile-default);
    padding: 0 var(--spacing-16);
  }
}

@media (max-width: 768px){
  .isDesktop{
    display: none;
  }
  .isMobile{
    display: grid;
  }

  .heroContainer{
    max-height: 400px;
  }
}

@media (max-width: 608px) {
  .heroContainer{
    min-height: auto;
  }
}

@media (max-width: 480px) {}
